
import axios from "axios";
import {
    axiosCall,
    getAPIChangePlanConfig,
    getAPIChatHistoryConfig,
    getAPIChatMessagesConfig, getAPIConfig, getAPIMessagesDataConfig,
    getAPISendMessagesConfig,
} from "../app.config";


export const adminLoginApi = async (username, password) => {
    const envKey = 'REACT_APP_API_ADMIN_LOGIN';
    const inputData = {username:username,password:password};
    const axiosConfig = getAPIConfig(envKey,'','POST',inputData);
    let data = {data:{}, error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;


    if (responseData.status){
        data.data = responseData.data;
        data.error = '';
    }else{
        data.data = null;
        data.error = responseData.message;
    }

    return data;
}

export const getAdminDataAPi = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_GET_DATA';
    const axiosConfig = getAPIConfig(envKey,verificationKey,'POST', inputData);

    return  await axiosCall(axiosConfig);

};

export const adminResetQuotaApi = async (params) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_RESET_USER_QUOTA';
    const axiosConfig = getAPIConfig(envKey,verificationKey,'POST');
    let data = {data:{}, error: ''};

    const response = await axios(axiosConfig);
    const responseData = await response.data;


    if (responseData.status){
        data.data = responseData.data;
        data.error = '';
    }else{
        data.data = null;
        data.error = responseData.message;
    }

    return data;
};

export const adminUpdateShopDataApi = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_UPDATE_SHOP_DATA';
    const axiosConfig = getAPIConfig(envKey,verificationKey,'POST',inputData);

    return  await axiosCall(axiosConfig);
};

export const getLumaUsageApi = async (params,inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_ADMIN_LUMA_USAGE_PATH';
    const axiosConfig = getAPIConfig(envKey,verificationKey,'POST',inputData);

    return  await axiosCall(axiosConfig);
};

