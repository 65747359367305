import { Navbar, Nav } from 'rsuite';
import HomeIcon from '@rsuite/icons/legacy/Home';
import CogIcon from '@rsuite/icons/legacy/Cog';
import {NavLink} from "react-router-dom";

export const CustomNavBar = ({ onSelect, activeKey, ...props }) => {
    return (
        <Navbar {...props}>
            {/*<Navbar.Brand href="/admin"></Navbar.Brand>*/}
            <Nav onSelect={onSelect} activeKey={activeKey}>
                <Nav.Item eventKey="1" icon={<HomeIcon />} as={NavLink} to={'/admin'}>Home</Nav.Item>
                <Nav.Item eventKey="2" as={NavLink} to={'/admin/jobs'}>Pending Predictions</Nav.Item>
                <Nav.Item eventKey="3" as={NavLink} to={'/admin/predictions'}>Generated Predictions</Nav.Item>
                <Nav.Item eventKey="4" as={NavLink} to={'/admin/plans'}>Payment Plans</Nav.Item>
                <Nav.Item eventKey="5" as={NavLink} to={'/admin/settings'}>Settings</Nav.Item>

                {/*<Nav.Item eventKey="3">Products</Nav.Item>*/}
                {/*<Nav.Menu title="About">*/}
                {/*    <Nav.Item eventKey="4">Company</Nav.Item>*/}
                {/*    <Nav.Item eventKey="5">Team</Nav.Item>*/}
                {/*    <Nav.Item eventKey="6">Contact</Nav.Item>*/}
                {/*</Nav.Menu>*/}
            </Nav>
            {/*<Nav pullRight>*/}
            {/*    <Nav.Item icon={<CogIcon />}>Settings</Nav.Item>*/}
            {/*</Nav>*/}
        </Navbar>
    );
};
