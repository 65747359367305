import Panel from "rsuite/Panel";
import {
    Button,
    Form,
    Table,
    Toggle,
    Input,
    Placeholder,
    IconButton,
    Pagination,
    SelectPicker,
    ButtonToolbar, Whisper, Tooltip, ButtonGroup, InputGroup, Grid, Row, Col
} from "rsuite";
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import {Link, useNavigate} from "react-router-dom";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {getAuthSession} from "../../../servies/common.services";
import ToasterMessage from "../../../components/common/toaster_message";

import {generatedPredictionApi,} from "../../../servies/settings.services";
import {handleMouseMoveZoom, handleMouseOutZoom} from "../../../components/common/util";
import {Card, Icon, InlineStack, Text} from "@shopify/polaris";
import {Icon as RIcon} from "@rsuite/icons";
import {ReactComponent as thumbsUpActiveSvg} from "../../../assets/thumb_up_active.svg";
import {ReactComponent as thumbsDownActiveSvg} from "../../../assets/thumb_down_active.svg";
import {ReactComponent as questionMarkSvg} from "../../../assets/question-marksvg.svg";
import {getSERVER_URL} from "../../../app.config";
import {CustomToolTip} from "../../../components/common/CustomToolTip";

const {Column, HeaderCell, Cell} = Table;

export const GeneratedPredictions = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [paramsData, setParamsData] = useState('');
    const [authData, setAuthData] = useState({});
    const [selectedRow, setSelectedRow] = useState({});
    const [predictionsData, setPredictionsData] = useState([]);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [usersData, setUsersData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(0);

    const navigate = useNavigate();

    const imagesStyle = {
        position: 'relative',
        cursor: 'pointer',
        marginBottom: '16px',
        borderRadius: '6px',
        width: '250px',
    }

    const getGeneratedPredictions = (params) => {
        const inputData = {
            limit: limit,
            offset: (page - 1) * limit,
            kwargs: {
                search_term: searchTerm,
                user_id: selectedUser
            }
        }
        console.log('limit, page', limit, page)

        setIsLoading(true);
        generatedPredictionApi(params.toString(), inputData)
            .then(res => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }

                setPredictionsData(data.predictions);
                setTotalData(data.total_data);
                const userDataLabels = data.users_data.map(
                    item => ({
                        label: `${item.count} | ${item.store_name} | ${item.domain}`,
                        value: item.user_id
                    })
                );
                userDataLabels.unshift({label: 'All', value: 0,});
                console.log('userDataLabels', userDataLabels)
                setUsersData(userDataLabels);

            })
            .catch(reason => {
                ToasterMessage.error(reason.toString() + 'Settings');
                console.log(reason);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        const authSession = getAuthSession();
        if (authSession === null) {
            navigate('/admin/login');
            return;
        }

        const token = authSession.token;
        setAuthData(authSession);

        const params = `token=${token}&is_admin=true`;
        setParamsData(params);

        getGeneratedPredictions(params);


    }, [limit, page, selectedUser, searchTerm]);



    return (
        <Fragment>
            <Panel>
                <div>
                    <Grid fluid style={{marginBottom: 10}}>
                        <Row>
                            <Col xs={24} sm={12} md={8}>
                                <CustomToolTip
                                    toolText={'Search by Store Name, My Shopify Domain, Domain, First Name, Last Name, Email, Shop Owner '}
                                >
                                    <Input
                                        size={'sm'}
                                        placeholder="Search by Store Name, My Shopify Domain, Domain, First Name, Last Name, Email, Shop Owner"
                                        value={searchTerm}
                                        onChange={(value) => setSearchTerm(value)}
                                        style={{marginBottom: 10, width: 200}}

                                    />
                                </CustomToolTip>
                            </Col>
                            <Col xs={24} sm={12} md={8}>

                                <SelectPicker
                                    data={usersData}
                                    size={'lg'}
                                    style={{width: 300}}
                                    onChange={setSelectedUser}
                                    value={selectedUser}
                                />

                            </Col>
                        </Row>

                    </Grid>
                    <Table
                        height={520}
                        rowHeight={400}
                        // width={1000}
                        data={predictionsData}
                        loading={isLoading}
                        onRowClick={data => {
                            setSelectedRow(data);
                        }}
                        bordered={true}
                        rowKey="id"
                    >
                        <Column width={360} resizable>
                            <HeaderCell>Start Image</HeaderCell>

                            <Cell style={{padding: '6px'}}>
                                {rowData => (

                                    <div
                                        style={imagesStyle}
                                        onMouseMove={handleMouseMoveZoom}
                                        onMouseOut={handleMouseOutZoom}
                                    >
                                        <Card>
                                            <img
                                                src={rowData.start_image_url}
                                                style={{width: '100%'}}
                                                alt={''}
                                            />
                                        </Card>

                                    </div>

                                )}
                            </Cell>
                        </Column>

                        <Column width={360} resizable>
                            <HeaderCell>End Image</HeaderCell>

                            <Cell style={{padding: '6px', height: '250px'}} height={'250px'}>
                                {rowData => (

                                    <div
                                        style={imagesStyle}
                                        onMouseMove={handleMouseMoveZoom}
                                        onMouseOut={handleMouseOutZoom}
                                    >
                                        <Card>
                                            <img
                                                src={rowData.end_image_url ? rowData.end_image_url : `${getSERVER_URL()}/images/no_image.jpg`}
                                                style={{width: '100%'}}
                                                alt={''}
                                            />
                                        </Card>

                                    </div>
                                )}
                            </Cell>
                        </Column>

                        <Column width={360} resizable>
                            <HeaderCell>Video</HeaderCell>

                            <Cell style={{padding: '6px'}} >
                                {rowData => (

                                    <div
                                        style={imagesStyle}
                                    >
                                        {rowData.state === 'completed' ?
                                            <Card>

                                                <InlineStack align={'start'} gap={'200'}>
                                                    <video width="100%" controls key={rowData.video.url}>
                                                        <source src={rowData.video.url} type="video/mp4"/>
                                                    </video>
                                                </InlineStack>

                                            </Card>
                                            :
                                            <Placeholder.Graph active width={320} height={240}/>
                                        }
                                    </div>
                                )}
                            </Cell>
                        </Column>

                        <Column width={360} resizable>
                            <HeaderCell>Feedback</HeaderCell>

                            <Cell style={{padding: '6px'}}>
                                {rowData => (
                                    <>
                                        <Text as={'span'}>{rowData.created_at}</Text>
                                        <br/>
                                        <IconButton
                                            disabled={true}
                                            size={'sm'}
                                            icon={
                                                <RIcon
                                                    as={rowData.feedback ? rowData.feedback.is_thumbs_up ? thumbsUpActiveSvg : thumbsDownActiveSvg : questionMarkSvg}
                                                    // style={thumbsStyle}
                                                    style={{
                                                        fontSize: 35
                                                    }}
                                                    color={'transparent'}
                                                />
                                            }
                                        />
                                        <br/>
                                        <Link
                                            to={{pathname: '/', search: `?${paramsData}&shop=${rowData.shop_url}`,}}
                                            target={'_blank'}>
                                            {rowData.shop_url}
                                        </Link>
                                    </>
                                )}
                            </Cell>
                        </Column>

                    </Table>
                    <div style={{padding: 20}}>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={5}
                            size="xs"
                            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                            total={totalData}
                            limitOptions={[10, 30, 50]}
                            limit={limit}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={(l) => {
                                setLimit(l);
                                setPage(1);
                            }}
                        />
                    </div>
                </div>


            </Panel>
        </Fragment>
    );

}