import React, {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {
    Bleed, BlockStack,
    Box, Button, Card, ChoiceList, DataTable, Divider, Filters,
    Frame, IndexFilters, IndexFiltersMode, IndexTable, InlineGrid, InlineStack, Layout, LegacyFilters,
    Page, RangeSlider, Spinner, TextField,
    Toast, useBreakpoints, useIndexResourceState, useSetIndexFiltersMode
} from '@shopify/polaris';
import {useLocation} from "react-router-dom";
import {getGeneratedProductsAPI, getProductsAPI, getShopifyProductsAPI} from "../servies/products.services";
import {FilterIcon, ImageAddIcon} from "@shopify/polaris-icons";
import ProductsIndexTableRow from "./ProductsIndexTableRow";
import {AppContext} from "../lib/contextLib";
import {debounce} from "lodash";
import AIVideoIndexTableRow from "./AIVideoIndexTableRow";


export default function CustomIndexTable({
                                             selectedTab,
                                             setQueryValue,
                                             queryValue,
                                             setKwargs,
                                             kwargs,
                                             goToUrl,
                                             setGoToUrl,
                                             isProductLoading,
                                             setIsProductLoading,

                                         }) {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const [products, setProducts] = useState([]);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [prevPageUrl, setPrevPageUrl] = useState(null);

    /*const [isProductLoading, setIsProductLoading] = useState(false);
    const [goToUrl, setGoToUrl] = useState(null);
    const [queryValue, setQueryValue] = useState(null);
    const [kwargs, setKwargs] = useState({'status': ''});
    const [searchQuery, setSearchQuery] = useState('');*/

    const {mode, setMode} = useSetIndexFiltersMode();
    const [sortSelected, setSortSelected] = useState(['order asc']);
    const [productStatus, setProductStatus] = useState([]);
    const [itemStrings, setItemStrings] = useState([]);
    const [selected, setSelected] = useState(0);
    const [isPrevious, setIsPrevious] = useState(false);
    const [isDebouncing, setIsDebouncing] = useState(false);


    const {
        selectedResources,
        allResourcesSelected, handleSelectionChange,
    } = useIndexResourceState(products);

    const {
        totalAIImagesCount, setTotalAIImagesCount,
        totalProductGenerated, setTotalProductGenerated,
        totalProducts, setTotalProducts,
        isRefresh, setIsRefresh,
    } = useContext(AppContext);

    const resourceName = {singular: 'product', plural: 'products'};

    const rowMarkup = products.map(
        ({
             productID,
             productName,
             productStatus,
             totalInventory,
             totalVariants,
             productImage,
             productImages,
             predictions,
             isPredictionCreated,
         }, index,) => (
            selectedTab === 0 ?
             <ProductsIndexTableRow
                productID={productID}
                productName={productName}
                productStatus={productStatus}
                totalInventory={totalInventory}
                totalVariants={totalVariants}
                productImage={productImage}
                productImages={productImages}
                predictions={predictions}
                isPredictionCreated={isPredictionCreated}
                selectedTab={selectedTab}
                setIsRefresh={setIsRefresh}
                key={productID}
                index={index}
                selectedProduct={selectedResources.includes(productID)}
            />
            :
                <AIVideoIndexTableRow
                    productID={productID}
                    productName={productName}
                    productStatus={productStatus}
                    totalInventory={totalInventory}
                    totalVariants={totalVariants}
                    productImage={productImage}
                    productImages={productImages}
                    predictions={predictions}
                    isPredictionCreated={isPredictionCreated}
                    selectedTab={selectedTab}
                    setIsRefresh={setIsRefresh}
                    key={productID}
                    index={index}
                    selectedProduct={selectedResources.includes(productID)}
                />
        ),
    );

    const getProducts = useCallback((limit, offset) => {
        setIsProductLoading(true)
        const inputData = {limit: limit, offset: offset}
        getProductsAPI(params.toString(), inputData)
            .then(res => {
                const data = res.data;

                if (res.data === null) {
                    console.log('shopData', data);
                    return;
                }
                console.log('data', data)
                const pro = [];
                const productsList = data.products;
                const totalProductCount = data.total_products;
                for (let i = 0; i < productsList.length; i++) {
                    const productImages = productsList[i].images;
                    const predictions = productsList[i].predictions;
                    const aiImages = productsList[i].ai_images;
                    let aiImage = 'Create AI Image';
                    const productName = productsList[i].title;
                    const productID = productsList[i].id;
                    const isAIImage = aiImages.length !== 0;
                    const isPredicting = predictions.length !== 0;
                    let productImage = ''
                    let percentageCompleted = 0;
                    let predictionId = '';

                    if (productImages.length !== 0) {
                        productImage = productImages[0].src;
                    }
                    console.log(aiImages, productID, isAIImage)

                    if (isPredicting) {
                        percentageCompleted = predictions[0].percentage_completed
                        predictionId = predictions[0].prediction_id
                    }

                    pro.push({
                        productID,
                        productName,
                        productImage,
                        productImages,
                        aiImages,
                        isAIImage,
                        isPredicting,
                        percentageCompleted,
                        predictionId
                    });
                }
                setProducts(pro);
                setTotalProducts(totalProductCount);


            })
            .catch((e) => {
                console.log(e);
                // redirect('/not-auth');
            })
            .finally(() => setIsProductLoading(false))
    }, []);

    const getGeneratedProducts = useCallback((limit, offset, from_) => {

        const inputData = {limit: limit, offset: offset, from_: from_}
        setIsProductLoading(true);

        getGeneratedProductsAPI(params.toString(), inputData)
            .then(res => {
                const data = res.data;

                if (res.data === null) {
                    console.log('shopData', data);
                    return;
                }
                console.log('data', data)
                const pro = [];
                const productsList = data.products;

                for (let i = 0; i < productsList.length; i++) {
                    const productID = productsList[i].id;
                    const productName = productsList[i].title;

                    const productImages = productsList[i].images;
                    const predictions = productsList[i].predictions;
                    const productStatus = productsList[i].status;
                    const totalVariants = productsList[i].total_variants;
                    const totalInventory = productsList[i].total_inventory;

                    const isPredictionCreated = productsList[i].is_prediction_created;
                    let productImage = ''
                    if (productImages.length !== 0) {
                        productImage = productImages[0].src;
                    }

                    pro.push({
                        productID,
                        productName,
                        productStatus,
                        totalInventory,
                        totalVariants,
                        productImage,
                        productImages,
                        predictions,
                        isPredictionCreated,
                    });
                }

                setNextPageUrl(data.next_url);
                setPrevPageUrl(data.previous_url);
                setOffset(data.offset);
                setProducts(pro);

                setTotalProducts(data.total_products);
                setTotalAIImagesCount(data.total_ai_images);
                setTotalProductGenerated(data.total_products_generated);

            })
            .catch((e) => {
                console.log(e);
                // redirect('/not-auth');
            })
            .finally(() => setIsProductLoading(false))

    }, []);

    const getShopifyProducts = useCallback((limit, from_, _kwargs) => {

        // const status = productStatus.join(', ')
        // const title = queryValue
        // const _kwargs = {previous: isPrevious, status, title}

        const inputData = {limit: limit, from_: from_, kwargs: {..._kwargs, previous: isPrevious}}
        console.log(inputData)
        setIsProductLoading(true);

        getShopifyProductsAPI(params.toString(), inputData)
            .then(res => {
                const data = res.data;

                if (res.data === null) {
                    console.log('shopData', data);
                    return;
                }
                console.log('data', data)
                const pro = [];
                const productsList = data.products;

                for (let i = 0; i < productsList.length; i++) {
                    const productID = productsList[i].id;
                    const productName = productsList[i].title;
                    const productImages = productsList[i].images;
                    const predictions = productsList[i].predictions;
                    const productStatus = productsList[i].status;
                    const totalVariants = productsList[i].total_variants;
                    const totalInventory = productsList[i].total_inventory;

                    const isPredictionCreated = productsList[i].is_prediction_created;
                    let productImage = ''
                    if (productImages.length !== 0) {
                        productImage = productImages[0].src;
                    }

                    pro.push({
                        productID,
                        productName,
                        productStatus,
                        totalInventory,
                        totalVariants,
                        productImage,
                        productImages,
                        predictions,
                        isPredictionCreated,
                    });
                }

                setNextPageUrl(data.next_url);
                setPrevPageUrl(data.previous_url);
                setProducts(pro);

                setTotalProducts(data.total_products);
                setTotalAIImagesCount(data.total_ai_images);
                setTotalProductGenerated(data.total_products_generated);


            })
            .catch((e) => {
                console.log(e);
                // redirect('/not-auth');
            })
            .finally(() => setIsProductLoading(false))

    }, []);

    const debouncedFetchProducts = useCallback(() =>
        debounce(() => onChangeGetProducts(), 500), []
    );

    const onChangeGetProducts = () => {
        if (selectedTab === 1) {
            getGeneratedProducts(limit, offset, goToUrl);
        } else {
            getShopifyProducts(limit, goToUrl, kwargs);
        }
    };

    const tabs = itemStrings.map((item, index) => ({
        content: item,
        index,
        onAction: () => {
        },
        id: `${item}-${index}`,
        isLocked: index === 0,
        actions: []
    }));

    const onHandleCancel = () => {
    };

    function isEmpty(value) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value == null;
        }
    }

    const handleQueryValueChange = useCallback((value) => {
        setQueryValue(value);
        setKwargs((prevKwargs) => ({...prevKwargs, 'title': value}));
        setGoToUrl(null);
        // debouncedFetchProducts();

    }, []);

    const handleQueryValueRemove = useCallback(() => {
        setQueryValue('');
        setKwargs((prevKwargs) => ({...prevKwargs, 'title': ''}));
        setGoToUrl(null);
        // debouncedFetchProducts();

    }, []);

    const handleProductStatusChange = useCallback((value) => {
        setProductStatus(value);
        setKwargs((prevKwargs) => ({...prevKwargs, 'status': value.join(',')}));

    }, []);

    const handleProductStatusRemove = useCallback(() => {
        setProductStatus([]);
        setKwargs((prevKwargs) => ({...prevKwargs, 'status': ''}));
        // debouncedFetchProducts();


    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (isDebouncing) {
                onChangeGetProducts();
            }
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [isDebouncing, goToUrl, isRefresh, selectedTab, kwargs]);


    useEffect(() => {
        setIsDebouncing(true);
        return () => {
            setIsDebouncing(false);
        };
    }, [goToUrl, isRefresh, kwargs]);

    const primaryAction = {
        type: 'save',
        onAction: () => {
        },
        disabled: false,
        loading: false,
    };

    const pagination = {
        hasNext: selectedTab === 1 ? totalProductGenerated > (offset + limit) : nextPageUrl !== null,

        hasPrevious: selectedTab === 1 ? offset > 0 : prevPageUrl !== null,


        onNext: () => {
            setGoToUrl(nextPageUrl);
            setIsPrevious(false);
            if (selectedTab === 1) {
                setOffset(offset + limit);
            }
        },
        onPrevious: () => {
            setGoToUrl(prevPageUrl);
            if (prevPageUrl !== null) {
                setIsPrevious(true);
            }
            if (selectedTab === 1) {
                setOffset(offset - limit < 0 ? 0 : offset - limit);
            }

        },

    }

    const filters_old = [
        {
            key: 'status',
            label: 'Status',
            shortcut: true,
            filter: (
                <ChoiceList
                    title="Product Status"
                    titleHidden={true}
                    choices={[
                        {label: 'Active', value: 'active'},
                        {label: 'Draft', value: 'draft'},
                        {label: 'Archived', value: 'archived'},
                    ]}
                    selected={productStatus}
                    onChange={handleProductStatusChange}
                    allowMultiple
                />
            ),
        },
    ];

    const filters = [];
    const appliedFilters = [];

    const columnHeadings = [
        {title: 'Product'},
        {title: 'Status'},
        {title: 'Inventory'},
        {title: ''},
    ]

    if (productStatus && !isEmpty(productStatus)) {
        const key = 'status';
        appliedFilters.push({
            key,
            label: productStatus.map((val) => `${val.toString().toUpperCase()} Products`).join(', '),
            onRemove: handleProductStatusRemove,
        });
    }

    return (
        <Fragment>

            {
                // isProductLoading ? <Spinner size={'small'}/> :
                <>
                    {/*<Filters
                        sortSelected={sortSelected}
                        queryValue={queryValue}
                        queryPlaceholder="Searching in all"
                        onQueryChange={handleQueryValueChange}
                        onQueryClear={handleQueryValueRemove}
                        onSort={setSortSelected}
                        primaryAction={primaryAction}
                        cancelAction={{
                            onAction: onHandleCancel,
                            disabled: false,
                            loading: false,
                        }}
                        tabs={tabs}
                        selected={selected}
                        onSelect={setSelected}
                        filters={filters}
                        appliedFilters={appliedFilters}
                        onClearAll={() => {
                        }}
                        mode={mode}
                        setMode={setMode}
                        loading={isProductLoading}
                    />*/}

                    <IndexTable
                        loading={isProductLoading && selectedTab === 1}
                        resourceName={resourceName}
                        itemCount={selectedTab === 1 ? totalProductGenerated : totalProducts}
                        selectedItemsCount={
                            allResourcesSelected ? 'All' : selectedResources.length
                        }
                        selectable={false}
                        onSelectionChange={handleSelectionChange}
                        headings={columnHeadings}

                        pagination={
                            selectedTab === 1 ? totalProductGenerated > 10 ? pagination : null :
                            totalProducts > 10 ?
                                pagination : null
                        }

                        // loading={isProductLoading}


                    >
                        {rowMarkup}
                    </IndexTable>
                </>
            }
        </Fragment>
    )
}